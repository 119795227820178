import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const state = {
  S_STATISTICS: null,
  S_INSCRITOS: null,
  S_NO_INSCRITOS: null
}
const getters = {}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_GET_STATISTICS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('statistics', { params })

      commit('SET_DATA', {
        destination: 'S_STATISTICS',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_GET_STATISTICS [ACTION]', error)
      throw error
    }
  },
  async A_GET_NO_INSCRITOS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('profile/patients/noInscritos', { params })

      commit('SET_DATA', {
        destination: 'S_NO_INSCRITOS',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_GET_STATISTICS [ACTION]', error)
      throw error
    }
  },
  async A_GET_INSCRITOS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('profile/patients/inscritos', { params })

      commit('SET_DATA', {
        destination: 'S_INSCRITOS',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_GET_STATISTICS [ACTION]', error)
      throw error
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
