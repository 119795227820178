// IMPORTS OF PAGES

// CREATE ROUTES AND VALIDATIONS
const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'LoginForm' }
  },
  {
    path: '/login',
    // IMPORT LAYOUT  ==> DEFAULT
    component: () => import('../components/06-layouts/default.vue'),
    children: [
      {
        path: '',
        name: 'LoginForm',
        component: () => import('../components/05-pages/login/default.vue'),
        meta: {
          guest: true,
          title: 'Ingresar'
        }
      }
    ]
  },
  {
    path: '/editPassword/:id',
    component: () => import('../components/05-pages/login/editPassword.vue'),
    meta: {
      guest: true,
      title: 'Editar contraseña'
    }
  },
  {
    path: '/ConfirmationResetPassword',
    name: 'ConfirmationResetPasswordPage',
    component: () => import('../components/05-pages/login/confirmation.vue'),
    meta: {
      guest: true,
      title: 'Cambiar contraseña'
    }
  },
  {
    path: '/dashboard',
    // IMPORT LAYOUT  ==> DASHBOARD
    component: () => import('../components/06-layouts/dashboard.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'StadisticsPage' },
        meta: { requiresAuth: true }
      },
      {
        path: '/sessionReschedule',
        name: 'SessionReschedulePage',
        component: () => import('@/components/05-pages/sessionReschedule/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Sesiones Reagendadas'
        }
      },
      {
        path: '/pacientes',
        name: 'PatientsPage',
        component: () => import('../components/05-pages/patients/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Pacientes'
        }
      },
      {
        path: '/psicologos',
        name: 'PsychologistPage',
        component: () => import('../components/05-pages/psychologist/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Psicologos'
        }
      },
      {
        path: '/estadisticas',
        name: 'StadisticsPage',
        component: () => import('../components/05-pages/statistics/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Estadisticas'
        }
      },
      {
        path: '/profile',
        name: 'ProfilePage',
        component: () => import('../components/05-pages/profile/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Perfil'
        }
      },
      {
        path: '/report',
        name: 'ReportPage',
        component: () => import('../components/05-pages/report/default.vue'),
        meta: {
          requiresAuth: true,
          title: 'Informe'
        }
      }
    ]
  }
]

export default routes
