import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const state = {}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_SET_EMAIL ({ commit }, body) {
    try {
      const { data } = await HTTP.post('mail', body)
      return data
    } catch (error) {
      console.log('ERROR_SET_Email:', error)
      throw error
    }
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
