// IMPORT LIBRARIES
import Vue from 'vue'
// import createPersistedState from 'vuex-persistedstate'
// import SecureLS from 'secure-ls'
// IMPORT SERVICES
// store/Auth/index.js

import { HTTP } from '@/plugins/axios'

// const ls = new SecureLS({ isCompression: false })
const state = {
  S_PSYCHOLOGIST: [],
  S_CASES: []
}
const getters = {
  G_PSYCHOLOGIST: (state) => {
    return state.S_PSYCHOLOGIST.map(el => ({
      name: `${el.firstname} ${el.lastname}`,
      value: String(el.id)
    }))
  }
}
const mutations = {
  SET_DATA (state, params) {
    // console.log(params)
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_GET_PSYCHOLOGIST ({ commit }, Params) {
    try {
      const { data } = await HTTP.get('psychologist/list')

      commit('SET_DATA', {
        destination: 'S_PSYCHOLOGIST',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_LOGIN [ACTION]', error)
      throw error
    }
  },
  async A_GET_CASES ({ commit }, idSession) {
    try {
      const { data } = await HTTP.get(`sessionCases/${idSession}`)
      return data
    } catch (error) {
      console.error('ERROR_GET_CASES:', error)
      throw error
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations

  /*
    plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })]
  */
}
