import Vue from 'vue'
import jwtDecode from 'jwt-decode'
import { HTTP } from '@/plugins/axios'

const state = {
  S_PROFILE: null,
  S_AUTH: null
}
const getters = {
  isAuthenticated () {
    return !!Vue.$cookies.get('AUTH_TOKEN')
  }
}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  },
  M_SET_USER (state, username) {
    state.user = username
  },
  M_LOGOUT (state) {
    state.user = null
    state.posts = null
  }
}
const actions = {
  async A_REGISTER ({ dispatch }, form) {
    try {
      await HTTP.post('register', form)
      await dispatch('A_LOGIN', {
        username: form.username,
        password: form.password
      })
    } catch (error) {
      console.log('ERROR_REGISTER [ACTION]', error)
    }
  },
  async A_LOGIN ({ commit }, User) {
    try {
      const { data } = await HTTP.post('login', User)

      if (data.message === 'Perfil Inhabilitado') {
        return ({
          code: 300,
          message: data.message
        })
      }
      const allowedRoles = [7]
      const user = jwtDecode(data.data.token).user
      console.log('User: ', user)
      if (allowedRoles.includes(user.idRole)) {
        Vue.$cookies.set('AUTH_TOKEN', data.data.token)
        // console.log('test', data.data.token)
        // console.log('jwt decode', jwtDecode(data.data.token))
        commit('SET_DATA', {
          destination: 'S_AUTH',
          data: data.data.token
        })
        return ({
          message: 'Usuario logueado correctamente',
          isCorrectUser: true
        })
      }
      return ({
        message: 'Este usuario no esta permitido',
        isCorrectUser: false,
        code: 400
      })
      // Vue.$cookies.set('AUTH_TOKEN', data.data.token)
      // console.log('test', data.data.token)
      // console.log('jwt decode', jwtDecode(data.data.token))

      // commit('SET_DATA', {
      //   destination: 'S_AUTH',
      //   data: data.data.token
      // })
    } catch (error) {
      console.log('ERROR_LOGIN [ACTION]', error)
      throw error
    }
  },
  async A_LOGOUT ({ commit }) {
    const user = null
    Vue.$cookies.remove('AUTH_TOKEN')
    commit('SET_DATA', {
      destination: 'S_AUTH',
      data: user
    })
  },
  async A_CHANGE_PASSWORD ({ commit }, body) {
    try {
      const { data } = await HTTP.put('user/reset', body)
      console.log('TOKEN : ', data.data)
    } catch (error) {
      console.log(error)
    }
  },
  async A_VERIFY_PASSWORD ({ commit }, password) {
    try {
      const { data } = await HTTP.post('/user/verifyPassword', { password })
      return data.isCorrectPassword
    } catch (error) {
      console.log(error)
    }
  },
  async A_RESET_PASSWORD ({ commit }, newPassword) {
    try {
      const { data } = await HTTP.put('/user/update', { password_new: newPassword })
      Vue.$cookies.set('AUTH_TOKEN', data.data.token)
    } catch (error) {
      console.log(error)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
