import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const state = {
  S_INSTITUTIONS: [],
  S_INSTITUTION: {}
}
const getters = {}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_GET_INSTITUTIONS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('institution', { params })
      commit('SET_DATA', {
        destination: 'S_INSTITUTIONS',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_GET_INSTITUTION [ACTION]', error)
      throw error
    }
  },
  async A_GET_INSTITUTION ({ commit }, id) {
    try {
      const { data } = await HTTP.get(`institution/${id}/institution`)
      commit('SET_DATA', {
        destination: 'S_INSTITUTION',
        data: data.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
