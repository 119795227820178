import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const state = {
  S_CITIES: []
}
const getters = {
  G_CITIES () {
    const cities = state.S_CITIES.map(el => ({
      value: el.id,
      name: el.name
    }))
    cities.unshift({ value: null, name: 'Departamento' })
    return cities
  }
}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_GET_CITIES ({ commit }, params) {
    try {
      const { data } = await HTTP.get('cities', { params })
      commit('SET_DATA', {
        destination: 'S_CITIES',
        data
      })
      return data
    } catch (error) {
      console.log('ERROR_GET_CITY [ACTION]', error)
      throw error
    }
  },
  async GET_ALL_CITIES_ACTION ({ commit }, params) {
    try {
      const { data } = await HTTP.get('cities/list')
      commit('SET_DATA', {
        destination: 'S_CITIES',
        data
      })
    } catch (error) {
      console.log(error.message)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
