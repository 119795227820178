import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * -----------------------------------------
 *  Import Libraries for project
 * -----------------------------------------
 */
import './plugins/veeValidate'
import './plugins/vueCookies'
import './plugins/vueJsModal'
import './plugins/vueMoment'
import './plugins/vueDatePicker'
import './plugins/toast'
import './plugins/vueExcel'

/**
 * -----------------------------------------
 *  Import Directives for project
 * -----------------------------------------
 */
import './directives/vueClickOutside'

Vue.config.productionTip = false
Vue.prototype.TOAST = function (message, status, code) {
  localStorage.status = true
  const bodyToast = {
    position: 'top-right',
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.9,
    showCloseButtonOnHover: true,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false
  }
  switch (true) {
    case (status === 'info' || (code >= 100 && code < 200)):
      this.$toast.info(message, bodyToast)
      break
    case (status === 'success' || (code >= 200 && code < 300)):
      this.$toast.success(message, bodyToast)
      break
    case (status === 'warning' || (code >= 400 && code < 500)):
      this.$toast.warning(message, bodyToast)
      break
    case (status === 'error' || (code >= 500 && code < 600)):
      this.$toast.error(message, bodyToast)
      break
    case (code >= 300 && code < 400):
      this.$toast.warning(message, bodyToast)
      break
  }
  localStorage.removeItem('status')
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
