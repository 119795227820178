import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index'
import routes from './routes'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Mottiva'

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// VALIDATE AUTH VARIABLE FOR ACCESS PAGE
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (Vue.$cookies.get('AUTH_TOKEN')) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})
// VALIDATE AUTH FOR DENY PAGES WITH FORMS
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (Vue.$cookies.get('AUTH_TOKEN')) {
      next('/dashboard')
      return
    }
    next()
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title + ' - ' + DEFAULT_TITLE || DEFAULT_TITLE
  })
})

export default router
