import Vue from 'vue'
import Vuex from 'vuex'

// STORES
import Profile from './Profile/index'
import Auth from './Auth/index'
import Statistics from './Statistics/index'
import Institution from './Institution/index'
import Patients from './Patients/index'
import Psychologist from './Psychologist/index'
import City from './City/index'
import Email from './Email'
import Session from './Sessions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Auth,
    Profile,
    Statistics,
    Institution,
    Patients,
    Psychologist,
    City,
    Email,
    Session
  }
})
/**
 * THIS FORMAT NAMED FUNCTIONS :
 * State      -> S_
 * Mutation   -> M_
 * Action     -> A_
 * Getter     -> G_
 *
 * FORMAT FUNCTION LOGICS
 * SET      -> setting data in state
 * PUSH     -> pushing data in array state
 *
 * DEFAULT MUTATIONS
 * SET_DATA     -> set data in element state
 * PUSH_DATA    -> push data in element state
 */
