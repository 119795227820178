import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const ShoppingCart = {
  namespaced: true,
  state: {
    S_PROFILES: [],
    S_PROFILE: {}
  },

  getters: {
    resetData (state) {
      return state.data
    }
  },

  mutations: {
    SET_DATA (state, params) {
      // console.log(params)
      Vue.set(state, params.destination, params.data)
    },
    PUSH_DATA (state, params) {
      state[params.destination].push(params.data)
    }
  },

  actions: {
    async A_SET_PROFILE ({ commit }, profile) {
      try {
        const { data } = await HTTP.post('profile', profile)
        commit('SET_DATA', {
          destination: 'S_PROFILE',
          data: data
        })
        commit('PUSH_DATA', {
          destination: 'S_PROFILES',
          data: profile
        })
        return data
      } catch (error) {
        console.log('ERROR_SET_PROFILE [ACTION]', error)
        throw error
      }
    }
  }
}
export default ShoppingCart
