import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const state = {
  S_PATIENTS: [],
  S_CASES: [],
  S_PROFILE_CATALOGS: [],
  S_PROFILE_CATALOGS_COUNT: 0,
  S_MAX_PAGES: 0,
  S_CURRENT_PAGE: 0,
  S_PATIENT_STATES: []
}
const getters = {
  G_PATIENT_STATES (state) {
    const states = state.S_PATIENT_STATES.map(state => ({
      name: state.name,
      value: state.id
    }))
    states.unshift(
      {
        name: 'Estado',
        value: null
      },
      {
        name: 'No registrado',
        value: 0
      }
    )
    return states
  }
}
const mutations = {
  SET_DATA (state, params) {
    Vue.set(state, params.destination, params.data)
  },
  PUSH_DATA (state, params) {
    state[params.destination].push(params.data)
  }
}
const actions = {
  async A_GET_PROFILE_CATALOGS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('profile-catalog', { params })
      commit('SET_DATA', {
        destination: 'S_PROFILE_CATALOGS',
        data: data.patients
      })
      commit('SET_DATA', {
        destination: 'S_PROFILE_CATALOGS_COUNT',
        data: data.totalPatients
      })
      commit('SET_DATA', {
        destination: 'S_MAX_PAGES',
        data: data.maxPages
      })
    } catch (error) {
      console.log(error)
    }
  },
  async A_ONLY_GET_ALL_PROFILE_CATALOGS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('profile-catalog', { params })
      return data.patients
    } catch (error) {
      console.log(error)
    }
  },
  async A_GET_PATIENTS ({ commit }, params) {
    try {
      const { data } = await HTTP.get('patients/list', { params })

      commit('SET_DATA', {
        destination: 'S_PATIENTS',
        data: data.data
      })
      return data.data
    } catch (error) {
      console.log('ERROR_LOGIN [ACTION]', error)
      throw error
    }
  },
  async A_GET_CASES ({ commit }, idSession) {
    try {
      const { data } = await HTTP.get(`sessionCases/${idSession}`)
      return data
    } catch (error) {
      console.error('ERROR_GET_CASES:', error)
      throw error
    }
  },
  async A_GET_PATIENT_STATE ({ commit }) {
    try {
      const { data } = await HTTP.get('patients/states')
      commit('SET_DATA', {
        destination: 'S_PATIENT_STATES',
        data: data.data
      })
    } catch (error) {
      console.log(error)
    }
  },
  A_SET_CURRENT_PAGE ({ commit }, page) {
    commit('SET_DATA', {
      destination: 'S_CURRENT_PAGE',
      data: page
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations

  /*
    plugins: [createPersistedState({
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })]
  */
}
