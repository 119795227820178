import axios from 'axios'

function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: { Authorization: `Bearer ${getCookie('AUTH_TOKEN')}` }
})
