import Vue from 'vue'
import { HTTP } from '@/plugins/axios'

const Session = {
  namespaced: true,
  state: {
    S_SESSIONS: [],
    S_SESSIONS_RESCHEDULE: []
  },
  getters: {

  },
  mutations: {
    SET_DATA (state, params) {
      Vue.set(state, params.destination, params.data)
    }
  },
  actions: {
    async A_GET_SESSIONS ({ commit }, params) {
      try {
        const { data } = await HTTP.get('sessions', { params })
        commit('SET_DATA', {
          destination: 'S_SESSIONS',
          data: data.data
        })
        return data.data
      } catch (error) {
        console.log('ERROR_LOGIN [ACTION]', error)
        throw error
      }
    }
  }
}

export default Session
